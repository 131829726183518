<template>
  <lab-task> <lab-kinetics-permanganate /></lab-task>
</template>

<script>
import LabKineticsPermanganate from '../simulations/LabKineticsPermanganate';
import DynamicQuestionMixin from '@/tasks/mixins/dynamic-question';
import {defineComponent} from '@vue/composition-api';
import LabTask from './labs/LabTask';
export default defineComponent({
  name: 'KineticsPermanganateSIM',
  components: {LabKineticsPermanganate, LabTask},
  mixins: [DynamicQuestionMixin()],
});
</script>
